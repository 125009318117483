import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

// ------------------ Styled Components ------------------
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  max-width: 1200px;
  margin: auto;
  background-color: #f5f7fa;
  min-height: 100vh;
`;

const Title = styled.h2`
  color: #333;
  margin-bottom: 20px;
`;

const FormContainer = styled.form`
  background: #fff;
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #555;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 15px;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.2s ease;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px 15px;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
  transition: border-color 0.2s ease;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px 15px;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  transition: border-color 0.2s ease;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: #0056b3;
  }
`;

const CouponSection = styled.div`
  margin-bottom: 30px;
`;

const CouponCard = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
`;

const CouponHeader = styled.h4`
  margin: 0 0 10px 0;
  color: #333;
`;

const RemoveButton = styled.button`
  padding: 5px 10px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: #c82333;
  }
`;

const AddCouponButton = styled(Button)`
  background-color: #28a745;
  &:hover {
    background-color: #218838;
  }
`;

// ------------------ Main Component ------------------
const NotificationSettings = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    assignedAccount: "",
    googleSheetUrl: "",
    emailSubject: "",
    emailFrom: "",
    emailFromName: "",
    emailAdminTo: "",
    emailAdminBcc: "",
    emailAdminFrom: "",
    emailAdminFromName: "",
    custName: "",
    custAddress: "",
    thankYouTemplateUrl: "",
    message: "",
    bannerUrl: "",
    bannerLink: "",
    coupons: [],
  });
  const [blogNames, setBlogNames] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    // Fetch templates for dropdown
    axios
      .get("/api/templates")
      .then((response) => setTemplates(response.data))
      .catch((error) => console.error("Error fetching templates:", error));

    // If editing, fetch the existing notification settings
    if (id) {
      axios
        .get(`/api/notification-settings/${id}`)
        .then((response) => setFormData(response.data))
        .catch((error) =>
          console.error("Error fetching notification settings:", error)
        );
    }

    // Fetch WordPress blog names (accounts)
    axios
      .get("/api/blog-names")
      .then((response) => setBlogNames(response.data))
      .catch((error) => console.error("Error fetching blog names:", error));
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCouponChange = (index, field, value) => {
    setFormData(prev => {
      const updatedCoupons = [...prev.coupons];
      updatedCoupons[index] = { ...updatedCoupons[index], [field]: value };
      return { ...prev, coupons: updatedCoupons };
    });
  };

  const addCoupon = () => {
    if (formData.coupons.length < 25) {
      setFormData(prev => ({
        ...prev,
        coupons: [...prev.coupons, { imageUrl: "", title: "", recipient: "" }],
      }));
    }
  };

  const removeCoupon = (index) => {
    setFormData(prev => ({
      ...prev,
      coupons: prev.coupons.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      if (id) {
        // Update existing notification settings
        await axios.put(`/api/notification-settings/${id}`, formData);
      } else {
        // Create new notification settings
        await axios.post("/api/notification-settings", formData);
      }
      alert(`Notification settings ${id ? "updated" : "created"} successfully!`);
      navigate("/notifications");
    } catch (err) {
      console.error("Error saving notification settings:", err);
      setError("Failed to save notification settings. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <PageContainer>
        <Title>Loading...</Title>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Title>{id ? "Edit" : "Create"} Notification Settings</Title>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <FormContainer onSubmit={handleSubmit}>
      <FormGroup>
          <Label>Assigned Account:</Label>
          <Select
            id="accounts"
            name="assignedAccount"
            value={formData.assignedAccount}
            onChange={handleInputChange}
            required
          >
            <option value="">Select an account</option>
            {blogNames.map((blog) => (
              <option key={blog.blog_id} value={blog.blog_id}>
                {blog.domain}
              </option>
            ))}
          </Select>
        </FormGroup>

        <FormGroup>
          <Label>Google Sheet URL:</Label>
          <Input
            type="url"
            name="googleSheetUrl"
            value={formData.googleSheetUrl}
            onChange={handleInputChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Email Subject:</Label>
          <Input
            type="text"
            name="emailSubject"
            value={formData.emailSubject}
            onChange={handleInputChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Email From:</Label>
          <Input
            type="email"
            name="emailFrom"
            value={formData.emailFrom}
            onChange={handleInputChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Email From Name:</Label>
          <Input
            type="text"
            name="emailFromName"
            value={formData.emailFromName}
            onChange={handleInputChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Admin Email To:</Label>
          <Input
            type="email"
            name="emailAdminTo"
            value={formData.emailAdminTo}
            onChange={handleInputChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Admin Email BCC:</Label>
          <Input
            type="email"
            name="emailAdminBcc"
            value={formData.emailAdminBcc}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <Label>Admin Email From:</Label>
          <Input
            type="email"
            name="emailAdminFrom"
            value={formData.emailAdminFrom}
            onChange={handleInputChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Admin Email From Name:</Label>
          <Input
            type="text"
            name="emailAdminFromName"
            value={formData.emailAdminFromName}
            onChange={handleInputChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Customer Name:</Label>
          <Input
            type="text"
            name="custName"
            value={formData.custName}
            onChange={handleInputChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Customer Address:</Label>
          <Input
            type="text"
            name="custAddress"
            value={formData.custAddress}
            onChange={handleInputChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Thank You Template URL:</Label>
          <Select
            name="thankYouTemplateUrl"
            value={formData.thankYouTemplateUrl}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Template</option>
            {templates.map((template) => (
              <option key={template.id} value={template.title}>
                {template.title}
              </option>
            ))}
          </Select>
        </FormGroup>

        <FormGroup>
          <Label>Message:</Label>
          <TextArea
            name="message"
            rows="4"
            value={formData.message}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <Label>Banner URL:</Label>
          <Input
            type="url"
            name="bannerUrl"
            value={formData.bannerUrl}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <Label>Banner Link:</Label>
          <Input
            type="url"
            name="bannerLink"
            value={formData.bannerLink}
            onChange={handleInputChange}
          />
        </FormGroup>

        <CouponSection>
          <CouponHeader>Coupons</CouponHeader>
          {formData.coupons.map((coupon, index) => (
            <CouponCard key={index}>
              <FormGroup>
                <Label>Image URL:</Label>
                <Input
                  type="url"
                  value={coupon.imageUrl}
                  onChange={(e) =>
                    handleCouponChange(index, "imageUrl", e.target.value)
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>Title:</Label>
                <Input
                  type="text"
                  value={coupon.title}
                  onChange={(e) =>
                    handleCouponChange(index, "title", e.target.value)
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>Recipient:</Label>
                <Input
                  type="text"
                  value={coupon.recipient}
                  onChange={(e) =>
                    handleCouponChange(index, "recipient", e.target.value)
                  }
                />
              </FormGroup>
              <RemoveButton type="button" onClick={() => removeCoupon(index)}>
                Remove Coupon
              </RemoveButton>
            </CouponCard>
          ))}
          <AddCouponButton type="button" onClick={addCoupon}>
            Add Coupon
          </AddCouponButton>
        </CouponSection>

        <Button type="submit" disabled={loading}>
          {id ? "Update" : "Create"} Notification
        </Button>
      </FormContainer>
    </PageContainer>
  );
};

export default NotificationSettings;
