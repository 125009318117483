import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { slide as Menu } from 'react-burger-menu';
import './Sidebar.css'; // Add CSS for burger menu

const SidebarContainer = styled.div`
    width: 175px;
    max-width: 100%;
    height: 100vh;
    background: linear-gradient(132deg, #002644 -20%, #205ccc 120%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;

    @media (max-width: 768px) {
        display: none;
    }
`;

const Logo = styled.img`
    width: 165px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        width: 60px;
        margin-bottom: 20px;
    }
`;

const MenuContainer = styled.div`
    width: 100%;
    flex: 1;
`;

const MenuItem = styled(NavLink)`
    display: block;
    padding: 10px 10px;
    margin: 10px 0;
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    border-radius: 5px;

    &.active {
        background-color: #2294ff;
        color: #fff;
    }

    @media (max-width: 768px) {
        color: #fff;
    }
`;
const SubmenuItem = styled.button`
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    background: none;
    border: none;
    cursor: pointer;
    text-align: left;

    &:hover {
        background-color: #0056b3;
        border-radius: 5px;
    }

    @media (max-width: 768px) {
        color: #fff;
    }
`;
const Submenu = styled.div`
    margin-left: 20px;
    padding: 5px 10px;
    display: flex;
    flex-direction: column; 
    
    &.active {
        background-color: #2294ff;
        color: #fff;
    } 
    @media (max-width: 768px) {
        color: #fff;
    }
`;

const LogoutButton = styled.button`
    display: block;
    padding: 10px 20px;
    background-color: #2294ff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: 50px;

    @media (max-width: 768px) {
        margin-top: 30px;
    }
`;

const MobileHeader = styled.div`
    display: none;
    background-color: #f4f5f7;
    padding: 10px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    @media (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

const BurgerIcon = styled.div`
    @media (max-width: 768px) {
        font-size: 24px;
        cursor: pointer;
    }
`;

const Sidebar = ({ handleBack, handleBounces, handleOptouts, handleSend }) => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const userRole = Cookies.get('userRole'); // Retrieve the userRole from the cookie
    const [showReportSubmenu, setShowReportSubmenu] = useState(false);

    const handleLogout = () => {
        Cookies.remove('authToken'); // Remove the auth token cookie
        navigate('/'); // Redirect to the login page
    };

    const handleStateChange = (state) => {
        setMenuOpen(state.isOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const toggleSubmenu = () => {
        setShowReportSubmenu((prev) => !prev);
    };

    return (
        <>
            {/* Mobile View */}
            <MobileHeader>
                <Logo
                    src="https://image-upload-articlebuilder.s3.amazonaws.com/vehiclesend-logo-1729041229540.jpg"
                    alt="Logo"
                />
                <BurgerIcon onClick={() => setMenuOpen(!menuOpen)}></BurgerIcon>
            </MobileHeader>
            <Menu right isOpen={menuOpen} onStateChange={handleStateChange}>
                {userRole === 'admin' && <MenuItem to="/dashboard" onClick={closeMenu}>Dashboard</MenuItem>}
                <MenuItem to="/reports" onClick={() => { closeMenu(); toggleSubmenu(); }}>Reporting</MenuItem>
                {showReportSubmenu && (
                    <Submenu>
                        <SubmenuItem onClick={handleBack}>Back</SubmenuItem>
                        <SubmenuItem onClick={handleBounces}>Bounces</SubmenuItem>
                        <SubmenuItem onClick={handleOptouts}>Optouts</SubmenuItem>
                        <SubmenuItem onClick={handleSend}>Send</SubmenuItem>
                    </Submenu>
                )}
                {userRole === 'admin' && <MenuItem to="/builder" onClick={closeMenu}>Builder</MenuItem>}
                {userRole === 'admin' && <MenuItem to="/articles" onClick={closeMenu}>Articles</MenuItem>}
                {userRole === 'admin' && <MenuItem to="/templates" onClick={closeMenu}>Templates</MenuItem>}
                {userRole === 'admin' && <MenuItem to="/notifications" onClick={closeMenu}>Notifications</MenuItem>}
                {userRole === 'admin' && <MenuItem to="/schedule" onClick={closeMenu}>Scheduling</MenuItem>}
                {userRole === 'admin' && <MenuItem to="/user-accounts" onClick={closeMenu}>User Accounts</MenuItem>}
                <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
            </Menu>

            {/* Desktop View */}
            <SidebarContainer>
                <Logo
                    src="https://image-upload-articlebuilder.s3.amazonaws.com/vehiclesend-logo-1729041229540.jpg"
                    alt="Logo"
                />
                <MenuContainer>
                    {userRole === 'admin' && <MenuItem to="/dashboard">Dashboard</MenuItem>}
                    <MenuItem to="/reports" onClick={toggleSubmenu}>Reports</MenuItem>
                    {showReportSubmenu && (
                        <Submenu>
                            <SubmenuItem onClick={handleBack}>Back</SubmenuItem>
                            <SubmenuItem onClick={handleBounces}>Bounces</SubmenuItem>
                            <SubmenuItem onClick={handleOptouts}>Optouts</SubmenuItem>
                            <SubmenuItem onClick={handleSend}>Send</SubmenuItem>
                        </Submenu>
                    )}
                    {userRole === 'admin' && <MenuItem to="/builder">Builder</MenuItem>}
                    {userRole === 'admin' && <MenuItem to="/articles">Articles</MenuItem>}
                    {userRole === 'admin' && <MenuItem to="/templates">Templates</MenuItem>}
                    {userRole === 'admin' && <MenuItem to="/notifications">Notifications</MenuItem>}
                    {userRole === 'admin' && <MenuItem to="/schedule">Scheduling</MenuItem>}
                    {userRole === 'admin' && <MenuItem to="/user-accounts">User Accounts</MenuItem>}
                </MenuContainer>
                <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
            </SidebarContainer>
        </>
    );
};

export default Sidebar;
