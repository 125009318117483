// SingleReport.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { Pie, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { Chart, registerables } from 'chart.js';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';

// Example: import your authentication context (if you have one)
// import { useAuth } from '../contexts/AuthContext';

Chart.register(...registerables);

/* ------------------ Styled Components (copied from Reports.js) ------------------ */
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  width: 100%;
`;

const ReportsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 0px;
  width: auto;
  padding: 30px;
  background: #f6f6f6;

  @media (max-width: 768px) {
    margin-left: 0px;    
    margin-top: 0px;
    padding: 10px;
  }
`;

const ReportsTitle = styled.div`
  padding: 20px 0;
  margin-top: 0px;
  flex: 1 1 100%;
  text-align: center;
  background-color: #6e59a4;
  color: #ffffff;

  @media (max-width: 768px) {
    flex: 1 1 100%;
    width: 100%; 
    padding: 10px 0;
    margin-bottom: 20px;
  }
`;

const EmailSubjectContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 0px;
  }
`;

const EmailSubjectTitle = styled.h2`
  margin: 0;
  margin-top: 20px;
  width: 100%;
  flex: 1;
  text-align: center;
  font-size: 1.2em;
  padding: 10px 0;

  @media (max-width: 768px) {
    width: 100%;
    margin: auto;
    font-size: 1.0em;
  }
`;

const EmailDateTitle = styled.h2`
  margin: 0;
  width: 100%;
  flex: 1;
  text-align: center;
  font-size: 1.2em;

  @media (max-width: 768px) {
    width: 100%;
    margin: auto;
    font-size: 1em;
    margin-bottom:30px;
  }
`;

const ChartsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
  margin: auto;
  padding: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0px;
    padding-bottom: 50px;
    margin: 0px;
    width: 100%;
  }
`;

const PieChartContainer = styled.div`
  flex: 1;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
  padding: 40px;
  max-height: 300px;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    margin: 0;
    max-height: 300px;
    padding: 10px;
    padding-bottom: 60px;
  }
`;

const TimeSeriesChartContainer = styled.div`
  flex: 1;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
  padding: 40px;
  max-height: 300px;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    margin: 0;
    max-height: 300px;
    padding: 10px;
  }
`;

const ChartHeading = styled.h3`
  text-align: center;
  font-size: 0.9em;
  margin: 10px;
  margin-bottom: 20px; 
  @media (max-width: 768px) {
    font-size: 1.3em;
  }
`;

const MetricsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const MetricBlock = styled.div`
  background: white;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  flex: 1 1 calc(20% - 20px);
  text-align: center;

  @media (max-width: 768px) {
    flex: 1 1 100%;
    width: 92%;
    margin: 5px 0 5px 0;
  }
`;

const MetricTitle = styled.div`
  font-size: 1.2em;
  font-weight: bold;
`;

const MetricValue = styled.div`
  font-size: 2.0em;
  margin-top: 10px;

  @media (max-width: 1280px) {
    font-size: 1.5em;
  }

  @media (max-width: 768px) {
    font-size: 1.4em;
  }
`;

const MetricDescription = styled.div`
  margin-top: 5px;
  color: gray;
`;

const MetricDisclaimer = styled.div`
  margin-top: 5px;
  font-size: 10px;
  color: gray;
`;

const ClickedUrlsTable = styled.table`
  width: 100%;
  padding: 10px;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 0.9em;
  background: white;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0px;
    font-size: 0.7em;
  }
`;

const ClickedUrlsTh = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #6e59a4;
  color: #ffffff;

`;

const ClickedUrlsTd = styled.td`
  border: 1px solid #ddd;
  padding: 8px; 
  word-break: break-all; 
`;

/* ------------------ The SingleReport Component ------------------ */
const SingleReport = (/* You might accept props here */) => {
  const { blogId, emailId } = useParams();

  // Example: get the logged-in status from context or props.
  // const { isAuthenticated } = useAuth();
  // For this example, we'll assume:
  const isLoggedIn = false; // <-- Change this based on your app's auth state

  // These states hold the data we fetch
  const [loading, setLoading] = useState(true);
  const [senderName, setSenderName] = useState('');
  const [viewingEmail, setViewingEmail] = useState(null); // Holds subject, sent_on, etc.
  const [metrics, setMetrics] = useState({
    sent: 0,
    opens: 0,
    clicks: 0,
    unsubscribed: 0,
    bounces: 0,
    reactivity: 0
  });
  const [timeSeriesData, setTimeSeriesData] = useState({
    dates: [],
    sent: [],
    opens: [],
    clicks: []
  });
  const [clickedUrls, setClickedUrls] = useState([]);

  // On mount, fetch from /api/report/:blogId/:emailId
  useEffect(() => {
    const fetchSingleReport = async () => {
      try {
        const res = await axios.get(`/api/report/${blogId}/${emailId}`);
        // Suppose it returns: { success: true, data: { emailInfo, metrics, timeseries, clickedUrls } }
        const { emailInfo, metrics, timeseries, clickedUrls } = res.data.data;

        // emailInfo might have: subject, sent_on, sent, senderName
        setViewingEmail({
          id: emailInfo.emailId,
          subject: emailInfo.subject,
          sent: emailInfo.sent,
          sent_on: emailInfo.sentOn
        });
        setSenderName(emailInfo.senderName || '');

        // metrics might have: sent, opens, clicks, unsubscribed, ...
        setMetrics({
          ...metrics,
          // Example: add a reactivity metric (clicks/opens)
          reactivity: metrics.opens > 0
            ? ((metrics.clicks / metrics.opens) * 100).toFixed(1)
            : 0
        });

        // timeseries might be { dates: [...], sent: [...], opens: [...], clicks: [...] }
        setTimeSeriesData(timeseries);

        // clickedUrls: array of { url, unique_clicks, total_views }
        setClickedUrls(clickedUrls);

      } catch (err) {
        console.error('Error fetching single report:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchSingleReport();
  }, [blogId, emailId]);

  // Modify the download function to optionally check authentication (even though the button is hidden)
  const handleDownloadLeads = async (clickedUrl) => {
    if (!isLoggedIn) {
      alert('You must be logged in to download leads.');
      return;
    }
    try {
      // We have blogId, emailId from useParams:
      const response = await axios.get('/api/download-leads', {
        params: {
          blogId,
          emailId,
          url: clickedUrl
        },
        responseType: 'blob' // we'll get a CSV file
      });
  
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'leads.csv'); // or a name that suits you
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading leads:', error);
    }
  };
  

  // ------------------ Chart Generators (Same logic from Reports.js) ------------------ //
  const generateChartData = (metrics) => {
    const total = metrics.sent;
    const estimatedOpens = metrics.opens;
    const openedAndClicked = metrics.clicks;
    const optOuts = metrics.unsubscribed;
    const bounces = metrics.bounces || 0; 
    const remaining = total - (estimatedOpens + openedAndClicked + optOuts + bounces);

    return {
      labels: [
        `Estimated Opens* - ${((estimatedOpens / total) * 100).toFixed(1)}%`,
        `Opened & Clicked - ${((openedAndClicked / total) * 100).toFixed(1)}%`,
        `Opt-outs - ${((optOuts / total) * 100).toFixed(1)}%`,
        `Bounced - ${((bounces / total) * 100).toFixed(1)}%`,
        `Remaining - ${((remaining / total) * 100).toFixed(1)}%`
      ],
      datasets: [
        {
          data: [estimatedOpens, openedAndClicked, optOuts, bounces, remaining],
          backgroundColor: ['#4BC0C0', '#FF6384', '#FF9F40', '#FFCE56', '#E7E9ED'],
          hoverBackgroundColor: ['#36A2EB', '#FF6384', '#FF9F40', '#FFCE56', '#E7E9ED']
        }
      ]
    };
  };

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'left'
      }
    }
  };

  const generateTimeSeriesData = (timeSeriesMetrics) => {
    return {
      labels: timeSeriesMetrics.dates,
      datasets: [
        {
          type: 'bar',
          label: 'Sent',
          data: timeSeriesMetrics.sent,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        },
        {
          type: 'line',
          label: 'Opens',
          data: timeSeriesMetrics.opens,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
          fill: false,
          lineTension: 0.1
        },
        {
          type: 'line',
          label: 'Clicks',
          data: timeSeriesMetrics.clicks,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
          fill: false,
          lineTension: 0.1
        }
      ]
    };
  };

  const timeSeriesOptions = {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month'
        }
      },
      y: {
        beginAtZero: true
      }
    }
  };

  // A helper to format the mailing date
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  };

  const calculatePercentage = (value, total) => {
    return total > 0 ? `${((value / total) * 100).toFixed(1)}%` : '0%';
  };

  // ------------------ Render ------------------ //
  if (loading) {
    return (
      <SpinnerContainer>
        <ClipLoader color="#007bff" loading={loading} css={override} size={150} />
      </SpinnerContainer>
    );
  }

  if (!viewingEmail) {
    return <p>No data found for blogId: {blogId}, emailId: {emailId}</p>;
  }

  return (
    <ReportsContainer>
      <ReportsTitle>
        <h1>Mailing Overview Report</h1>
      </ReportsTitle>

      <EmailSubjectContainer>
        <EmailSubjectTitle>Subject: "{viewingEmail.subject?.trim()}"</EmailSubjectTitle>
      </EmailSubjectContainer>
      <EmailSubjectContainer>
        <EmailDateTitle>Mailed on: {formatDate(viewingEmail.sent_on)}</EmailDateTitle>
      </EmailSubjectContainer>
      {/* Charts */}
      <ChartsContainer>
        <PieChartContainer>
          <ChartHeading>Current Mailing</ChartHeading>
          <Pie data={generateChartData(metrics)} height="300px"  options={pieOptions} />
        </PieChartContainer>

        <TimeSeriesChartContainer>
          <ChartHeading>Historical Summary</ChartHeading>
          <Line 
            data={generateTimeSeriesData(timeSeriesData)}
            options={timeSeriesOptions}
          />
        </TimeSeriesChartContainer>
      </ChartsContainer>

      {/* Metrics */}
      <MetricsContainer>
        <MetricBlock>
          <MetricTitle>Reach</MetricTitle>
          <MetricValue>{viewingEmail.sent}</MetricValue>
          <MetricDescription>Total people that received your email</MetricDescription>
        </MetricBlock>
        <MetricBlock>
          <MetricTitle>Total Opens*</MetricTitle>
          <MetricValue>{calculatePercentage(metrics.opens, viewingEmail.sent)}</MetricValue>
          <MetricDescription>
            {metrics.opens} total people that opened your email
          </MetricDescription>
          <MetricDisclaimer></MetricDisclaimer>
        </MetricBlock>
        <MetricBlock>
          <MetricTitle>Clicks</MetricTitle>
          <MetricValue>{calculatePercentage(metrics.clicks, viewingEmail.sent)}</MetricValue>
          <MetricDescription>
            {metrics.clicks} total people that clicked a link
          </MetricDescription>
        </MetricBlock>
      </MetricsContainer>

      <MetricsContainer>
        <MetricBlock>
          <MetricTitle>Bounces</MetricTitle>
          <MetricValue>{metrics.bounces}</MetricValue>
          <MetricDescription>Emails that bounced back</MetricDescription>
        </MetricBlock>
        <MetricBlock>
          <MetricTitle>Reactivity</MetricTitle>
          <MetricValue>{metrics.reactivity}%</MetricValue>
          <MetricDescription>
            {metrics.clicks} clicks out of {metrics.opens} opens
          </MetricDescription>
        </MetricBlock>
        <MetricBlock>
          <MetricTitle>Unsubscribed</MetricTitle>
          <MetricValue>
            {calculatePercentage(metrics.unsubscribed, viewingEmail.sent)}
          </MetricValue>
          <MetricDescription>
            {metrics.unsubscribed} people opted out
          </MetricDescription>
        </MetricBlock>
      </MetricsContainer>

      {/* Clicked URLs Table */}
      <ClickedUrlsTable>
        <thead>
          <tr>
            <ClickedUrlsTh>URL</ClickedUrlsTh>
            <ClickedUrlsTh>Readers</ClickedUrlsTh>
            <ClickedUrlsTh>Views</ClickedUrlsTh>
            {/* Only show the "Leads" column if logged in */}
            {isLoggedIn && <ClickedUrlsTh>Leads</ClickedUrlsTh>}
          </tr>
        </thead>
        <tbody>
          {clickedUrls.map((url, index) => (
            <tr key={index}>
              <ClickedUrlsTd>{url.url}</ClickedUrlsTd>
              <ClickedUrlsTd>{url.unique_clicks}</ClickedUrlsTd>
              <ClickedUrlsTd>{url.total_views}</ClickedUrlsTd>
              {isLoggedIn && (
                <ClickedUrlsTd>
                  <button onClick={() => handleDownloadLeads(url.url)}>
                    Download
                  </button>
                </ClickedUrlsTd>
              )}
            </tr>
          ))}
        </tbody>
      </ClickedUrlsTable>
    </ReportsContainer>
  );
};

export default SingleReport;
