// Articles.js
import Config from '../Config';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// --------------- Styled Components ---------------

const Toolbar = styled.div`
  display: flex;
  justify-content: center; /* Centers the buttons */
  gap: 15px;
  padding: 10px;
  background-color: #6e59a4;
`;

const ToolbarButton = styled.button`
  
  background-color: #007bff;
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    
    background-color: #0056b3;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 800px;
  margin: auto;
`;

const Section = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  margin-bottom: 10px;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 10px;  
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Ensures padding doesn't overflow */
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const HalfWidth = styled.div`
  width: 50%;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;  
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const SelectInput = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

// --------- Modal Styles ---------
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: ${({ show }) => (show ? 'block' : 'none')};
  z-index: 999;
`;

const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  max-width: 800px;
  max-height: 80%;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 5px;
  padding: 20px;
`;

const ModalTitle = styled.h2`
  margin-top: 0;
`;

const ArticleListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const ArticleListItem = styled.div`
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }
`;

const ArticleDate = styled.div`
  width: 100%;
  padding: 10px;
  border-right: 1px solid #ccc;
  font-weight: bold;
`;



const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;

const ModalButton = styled.button`
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

// --------- Utility Functions ---------
const getS3ImageUrl = (fileName, region = 'us-east-1') => {
  return `https://image-upload-articlebuilder.s3.${region}.amazonaws.com/${fileName}`;
};

// --------- TinyMCE Editor Component ---------
const TinyMCEEditor = ({ value, onEditorChange }) => {
  const editorRef = useRef(null);

  const handleImageUpload = async (blobInfo) => {
    const file = blobInfo.blob();
    const fileName = `${Date.now()}_${file.name}`;
    const fileType = file.type;

    try {
      // 1. Get Presigned URL
      const presignedResponse = await axios.post('/api/generate-presigned-url', {
        fileName,
        fileType,
      });

      if (presignedResponse.status !== 200) {
        console.error('Failed to fetch presigned URL', presignedResponse.statusText);
        throw new Error('Failed to fetch presigned URL');
      }

      const { url } = presignedResponse.data;
      if (!url) {
        console.error('Presigned URL is undefined');
        throw new Error('Presigned URL is undefined');
      }

      // 2. Upload direct to S3
      const uploadResponse = await axios.put(url, file, {
        headers: { 'Content-Type': fileType },
      });

      if (uploadResponse.status === 200 || uploadResponse.status === 204) {
        // 3. Return final public URL
        const region = 'us-east-1';
        return getS3ImageUrl(fileName, region);
      } else {
        console.error('Image upload failed:', uploadResponse.status);
        throw new Error('Upload failed');
      }
    } catch (error) {
      console.error('Error during image upload:', error);
      throw new Error('Image upload failed. Please try again.');
    }
  };

  return (
    <Editor
      apiKey="m55v8vhqgiqm3qjbwg7i5kz1aihykam7xntip3isbka36yx6" // Replace with your TinyMCE API key
      onInit={(evt, editor) => (editorRef.current = editor)}
      value={value}
      init={{
        height: 500,
        menubar: true,
        plugins:
          'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount code',
        toolbar:
          'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | code',

        automatic_uploads: true,
        images_upload_handler: handleImageUpload, 
        file_picker_types: 'image',
        image_title: true,
        automatic_uploads: true,
        file_picker_callback: (callback, value, meta) => {
          if (meta.filetype === 'image') {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.onchange = function () {
              const file = this.files[0];
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                const id = 'blobid' + new Date().getTime();
                const blobCache = editorRef.current.editorUpload.blobCache;
                const base64 = reader.result.split(',')[1];
                const blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);
                callback(blobInfo.blobUri(), { title: file.name });
              };
            };
            input.click();
          }
        },
        menu: {
          favs: { title: 'tools', items: 'code' },
        },
        menubar: 'file edit view insert format favs table help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      }}
      onEditorChange={onEditorChange}
    />
  );
};
function slugifyTitle(title) {
  return title
    .toLowerCase()
    // Replace any sequence of non-alphanumeric characters with a single hyphen
    .replace(/[^a-z0-9]+/g, '-')
    // Remove leading/trailing hyphens
    .replace(/(^-|-$)+/g, '');
}

// --------- Main Component ---------
const ArticlesEditor = () => {
  // Fields
  const [coverTitle, setCoverTitle] = useState('');
  const [coverSubtitle, setCoverSubtitle] = useState('');
  const [coverDescription, setCoverDescription] = useState('');
  const [articleContent, setArticleContent] = useState('');

  const [articleLink, setArticleLink] = useState('');
  const [articleLinkText, setArticleLinkText] = useState('Read more');

  const [articleType, setArticleType] = useState('Lifestyle');
  const [contentMonth, setContentMonth] = useState(null);

  // Modal states
  const [showModal, setShowModal] = useState(false);    // controls modal visibility
  const [articlesList, setArticlesList] = useState([]); // list of articles from DB
  const [selectedArticle, setSelectedArticle] = useState(null); // which article is selected
  const [selectedArticleId, setSelectedArticleId] = useState(null);
  //for posting articles
  const [showPostModal, setShowPostModal] = useState(false);
  const [templatesList, setTemplatesList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  // -------------- Toolbar Handlers --------------
  const handlePost = async () => {
    try {
      // 1. Fetch the templates from server (just like View Templates does).
      const res = await axios.get('/api/templates');
      setTemplatesList(res.data);
      setShowPostModal(true);
      setSelectedTemplate(null);
    } catch (err) {
      console.error('Error fetching templates:', err);
      alert('Unable to fetch templates.');
    }
  };
  
  
  const handleSelectTemplateForPost = (template) => {
    setSelectedTemplate(template);
  };
  
  const handleDeploy = async () => {
    if (!selectedTemplate) {
      alert('Please select a template to deploy.');
      return;
    }
  
    try {
      // 1) Merge placeholders locally
      const finalHtml = mergeTemplateWithArticle(selectedTemplate.templateContent, {
        page_title: coverTitle,
        page_subtitle: coverSubtitle,
        page_content: articleContent, // or combine coverDescription + articleContent if that’s your workflow
      });
  
      // 2) Slugify for a nice fileName (even if you're not uploading to S3,
      //    you can keep this logic or comment it out)
      const rawTitle = coverTitle || 'untitled';
      const slug = slugifyTitle(rawTitle);
      // const fileName = `${slug}.html`; // e.g. "this-is-a-test-article.html"
  
      // 3) (Commented out) Deploy to S3 if you ever want to re-enable
      /*
      const deployResponse = await axios.post('/api/deploy', {
        html: finalHtml,
        fileName,
      });
      if (deployResponse.status === 200) {
        console.log('S3 deploy successful, public URL:', deployResponse.data.url);
      }
      */
  
      // 4) Store the finalHtml in your DB
      const storeResponse = await axios.post('/api/deploy-and-store', {
        articleId: selectedArticleId,   // ID of the article being deployed
        templateId: selectedTemplate.id, // optional
        compiledHtml: finalHtml,
      });
  
      if (storeResponse.status === 200) {
        // The DB insertion likely returns something like: { deployId: 123, message: '...' }
        const newDeployId = storeResponse.data.deployId;
  
        // 5) Build your relative URL to view the deployed page
        const deployedUrl = `${Config.domain}/pages?accountid=1&pageid=${newDeployId}`;
  
        // 6) Show success message with your new URL
        alert(`Page deployed!\nURL: ${deployedUrl}`);
  
        // 7) Close your modal
        setShowPostModal(false);
      }
    } catch (err) {
      console.error('Error deploying article:', err);
      alert('Error deploying the article. Check console for details.');
    }
  };
  
// Utility to do simple placeholder merges
function mergeTemplateWithArticle(templateHtml, placeholders) {
  let mergedHtml = templateHtml;
  // For each placeholder, do a string replace
  // Example placeholders: [page_title], [page_subtitle], [page_content]
  for (const key of Object.keys(placeholders)) {
    const regex = new RegExp(`\\[${key}\\]`, 'g');
    mergedHtml = mergedHtml.replace(regex, placeholders[key] || '');
  }
  return mergedHtml;
}


  const handleViewArticles = async () => {
    try {
      // Fetch the articles from server
      const res = await axios.get('/api/articles');
      setArticlesList(res.data); 
      setShowModal(true);
      setSelectedArticle(null);
    } catch (err) {
      console.error('Error fetching articles:', err);
      alert('Unable to fetch articles.');
    }
  };

  const handleDelete = async () => {
    // Only allow delete if we have a loaded article
    if (!selectedArticleId) {
      alert('No article is currently loaded to delete.');
      return;
    }
    // Confirm
    if (!window.confirm('Are you sure you want to delete this article?')) {
      return;
    }
    try {
      const response = await axios.delete(`/api/articles/${selectedArticleId}`);
      if (response.status === 200) {
        alert(response.data.message); // "Article deleted successfully"
        clearAllFields(); // Clear fields after deletion
      }
    } catch (err) {
      console.error('Error deleting article:', err);
      alert('Error deleting article.');
    }
  };

  

  // -------------- Modal Handlers --------------

  // Called when user selects an article from the list
  const handleSelectArticle = (article) => {
    setSelectedArticle(article);
  };

  // Called when user clicks "Load"
  const handleLoadSelectedArticle = () => {
    if (!selectedArticle) return;

    // Populate fields from selected article
    setCoverTitle(selectedArticle.coverTitle || '');
    setCoverSubtitle(selectedArticle.coverSubtitle || '');
    setCoverDescription(selectedArticle.coverDescription || '');
    setArticleContent(selectedArticle.articleContent || '');
    setArticleLink(selectedArticle.articleLink || '');
    setArticleLinkText(selectedArticle.articleLinkText || 'Read more');
    setArticleType(selectedArticle.articleType || 'Lifestyle');

    // If stored as string, convert to Date
    // e.g. if contentMonth is "2025-01-01T00:00:00.000Z", parse
    let parsedDate = null;
    if (selectedArticle.contentMonth) {
      try {
        parsedDate = new Date(selectedArticle.contentMonth);
        // or handle month-year string differently if not an ISO date
      } catch (err) {
        console.warn('Failed to parse contentMonth', err);
      }
    }
    setContentMonth(parsedDate);
    setSelectedArticleId(selectedArticle.id);
    setShowModal(false);
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };

  // -------------- Date Picker Change --------------
  const handleMonthChange = (date) => {
    setContentMonth(date);
  };

  // -------------- Editor Handlers --------------
  const handleCoverDescriptionChange = useCallback((content) => {
    setCoverDescription(content); // HTML
  }, []);

  const handleArticleContentChange = useCallback((content) => {
    setArticleContent(content); // HTML
  }, []);

  // -------------- Save to DB --------------
  const clearAllFields = () => {
    setCoverTitle('');
    setCoverSubtitle('');
    setCoverDescription('');
    setArticleContent('');
    setArticleLink('');
    setArticleLinkText('Read more');
    setArticleType('Lifestyle');
    setContentMonth(null);
    setSelectedArticleId(null); // Clear the ID so subsequent saves are new
  };
  const formatContentMonth = (monthString) => {
    if (!monthString) return 'No Month';
    
    try {
      const date = new Date(monthString);
      // If date is invalid, this next step might produce an "Invalid Date"
      if (isNaN(date.getTime())) {
        return 'No Month';
      }
      // Format e.g. "March 2025"
      return new Intl.DateTimeFormat('en-US', {
        month: 'long',
        year: 'numeric',
      }).format(date);
    } catch (err) {
      console.error('Error formatting contentMonth:', err);
      return 'No Month';
    }
  };
  
  const handleArticleSave = async () => {
    const payload = {
      coverTitle,
      coverSubtitle,
      coverDescription,
      articleContent,
      articleLink,
      articleLinkText,
      articleType,
      contentMonth: contentMonth ? contentMonth.toISOString() : null,
    };

    try {
      let response;
      if (selectedArticleId) {
        // We have an ID, so let's update
        response = await axios.put(`/api/articles/${selectedArticleId}`, payload);
      } else {
        // No ID, create a new article
        response = await axios.post(`/api/articles`, payload);
      }

      if (response.status === 200) {
        alert(response.data.message); 
        clearAllFields();
      }
    } catch (error) {
      console.error('Error saving article:', error);
      alert('Error saving article. Please check console for details.');
    }
  };

  // -------------- Render --------------
  return (
    <>
      {/* ----------- Toolbar ----------- */}
      <Toolbar>
        <ToolbarButton onClick={handleViewArticles}>View Articles</ToolbarButton>
        <ToolbarButton onClick={handleDelete}>Delete</ToolbarButton>
        <ToolbarButton onClick={handlePost}>Post</ToolbarButton>
      </Toolbar>

      {/* ----------- Main Editor Page ----------- */}
      <PageContainer>
        <Section>
          <SectionTitle>Cover Page</SectionTitle>
          <Input
            type="text"
            placeholder="Title"
            value={coverTitle}
            onChange={(e) => setCoverTitle(e.target.value)}
          />
          <Input
            type="text"
            placeholder="Subtitle"
            value={coverSubtitle}
            onChange={(e) => setCoverSubtitle(e.target.value)}
          />

          {/* Dropdown & Month Picker */}
          <Row>
            <HalfWidth>
              <Label>Article Type</Label>
              <SelectInput
                value={articleType}
                onChange={(e) => setArticleType(e.target.value)}
              >
                <option value="Lifestyle">Lifestyle</option>
                <option value="Automotive">Automotive</option>
                <option value="Service">Service</option>
                <option value="Sales">Sales</option>
                <option value="Other">Other</option>
              </SelectInput>
            </HalfWidth>

            <HalfWidth>
              <Label>Content Month</Label>
              <StyledDatePicker
                selected={contentMonth}
                onChange={handleMonthChange}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                placeholderText="Select month and year"
              />
            </HalfWidth>
          </Row>

          <Input
            type="text"
            placeholder="Link (if outside article)"
            value={articleLink}
            onChange={(e) => setArticleLink(e.target.value)}
          />
          <Input
            type="text"
            placeholder="Link Text (default: Read more)"
            value={articleLinkText}
            onChange={(e) => setArticleLinkText(e.target.value)}
          />

          <TinyMCEEditor
            onEditorChange={handleCoverDescriptionChange}
            value={coverDescription}
          />
        </Section>

        <Section>
          <SectionTitle>Full Article</SectionTitle>
          <TinyMCEEditor
            onEditorChange={handleArticleContentChange}
            value={articleContent}
          />
        </Section>

        <SubmitButton onClick={handleArticleSave}>
          {selectedArticleId ? 'Update Article' : 'Save Article'}
        </SubmitButton>
      </PageContainer>
      {/* ----------- Modal for Posting Articles ----------- */}
      <ModalOverlay show={showPostModal}>
      <ModalContent>
        <ModalTitle>Select a Template to Deploy</ModalTitle>
        <ArticleListContainer>
          {templatesList.map((template) => (
            <ArticleListItem
              key={template.id}
              onClick={() => handleSelectTemplateForPost(template)}
              style={{
                backgroundColor:
                  selectedTemplate && selectedTemplate.id === template.id
                    ? '#e6f7ff'
                    : 'transparent',
              }}
            >
              <ArticleDate>
                {/* or just show template.title */}
                {template.title || 'Untitled'}
              </ArticleDate>
            </ArticleListItem>
          ))}
        </ArticleListContainer>

        <ModalButtonContainer>
          <ModalButton onClick={handleDeploy}>Deploy</ModalButton>
          <ModalButton onClick={() => setShowPostModal(false)}>Cancel</ModalButton>
        </ModalButtonContainer>
      </ModalContent>
    </ModalOverlay>

      {/* ----------- Modal for Viewing Articles ----------- */}
      <ModalOverlay show={showModal}>
        <ModalContent>
          <ModalTitle>Available Articles</ModalTitle>
          {/* Display a list of articles with date on left, preview on right */}
          <ArticleListContainer>
            {articlesList.map((article) => (
              <ArticleListItem
                key={article.id}
                onClick={() => handleSelectArticle(article)}
                style={{
                  backgroundColor:
                    selectedArticle && selectedArticle.id === article.id
                      ? '#e6f7ff'
                      : 'transparent',
                }}
              >
                {/* If you'd rather show created_at or contentMonth, you can customize */}
                <ArticleDate>
                 {/* Show 'contentMonth - coverTitle', using a fallback if either is missing */}
                 {formatContentMonth(article.contentMonth) + ' - ' + (article.coverTitle || 'Untitled')}
                </ArticleDate>
                
              </ArticleListItem>
            ))}
          </ArticleListContainer>

          <ModalButtonContainer>
            <ModalButton onClick={handleLoadSelectedArticle}>
              Load
            </ModalButton>
            <ModalButton onClick={handleCancelModal}>
              Cancel
            </ModalButton>
          </ModalButtonContainer>
        </ModalContent>
      </ModalOverlay>
    </>
  );
};

export default ArticlesEditor;
