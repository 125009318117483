import React, { useState, useEffect, useCallback, useRef } from 'react';
import CryptoJS from "crypto-js";
import styled from 'styled-components';
import axios from 'axios';
// import { FaArrowLeft, FaDownload, FaPaperPlane } from 'react-icons/fa';
import Modal from 'react-modal';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Pie, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { Chart, registerables } from 'chart.js';
import Sidebar from './Sidebar';
import 'react-quill/dist/quill.snow.css';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import Cookies from 'js-cookie';
import Config from '../Config'; // Adjust the path as needed

//integrate List verification reporting

Chart.register(...registerables);

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  width: 100%;
`;

const ReportsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: ${({ isViewingEmail }) => (isViewingEmail ? '70px' : '0px')};
  width: ${({ isGeneratingPDF }) => (isGeneratingPDF ? '1200px' : 'auto')};
  @media (max-width: 768px) {
    margin-left: 0px;
    width: 100%;
    margin-top: ${({ isViewingEmail }) => (isViewingEmail ? '70px' : '0px')};
  }
`;

const TopMenuBar = styled.div`
  width: calc(100% - 215px); // Adjust for sidebar width
  background-color: #007bff;
  padding: 10px;  // Reduced padding for a tighter fit
  margin-left: 215px;  // Align with the sidebar
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    margin: 0px;
    padding: 0px;
    width: 100%;
  }
`;

const ReportsBlock = styled.div`
  background: white;
  padding: 20px;
  padding-bottom:0px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex: 1 1 100%;

  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
`;

const ReportsTitle = styled.div`
  padding: 0px;
  margin-top: 0px;
  flex: 1 1 100%;
  text-align: center;

  @media (max-width: 768px) {
    flex: 1 1 100%;
    width: 100%;
  }
`;

const Dropdown = styled.select`
  padding: 10px;
  margin-left: 20px;
  margin-bottom: 20px;
  width: 200px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 14px;
  background-color: #f2f2f2;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 14px;
`;

const Button = styled.button`
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  &:hover {
    background-color: #0056b3;
  }
`;

const MetricsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const MetricBlock = styled.div`
  background: white;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  flex: 1 1 calc(20% - 20px);
  text-align: center;

  @media (max-width: 768px) {
    flex: 1 1 100%;
    width: 92%;
    margin: 5px 0 5px 0;
  }
`;

const MetricTitle = styled.div`
  font-size: 1.2em;
  font-weight: bold;
`;

const MetricValue = styled.div`
  font-size: 2.0em;
  margin-top: 10px;

  @media (max-width: 1280px) {
    font-size: 1.5em;
  }

  @media (max-width: 768px) {
    font-size: 1.4em;
  }
`;

const MetricDescription = styled.div`
  margin-top: 5px;
  color: gray;
`;
const MetricDisclaimer = styled.div`
  margin-top: 5px;
  font-size: 10px;
  color: gray;
`;
const EmailSubjectContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

const ClickedUrlsTable = styled.table`
  width: 100%;
  padding: 10px;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 0.9em;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0px;
    font-size: 0.7em;
  }
`;

const ClickedUrlsTh = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f2f2f2;
`;

const ClickedUrlsTd = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

const EmailSubjectTitle = styled.h2`
  margin: 0;
  margin-top: 20px;
  width: 100%;
  flex: 1;
  text-align: center;
  font-size: 1.4em;

  @media (max-width: 768px) {
    width: 100%;
    margin: auto;
    font-size: 1.0em;
  }
`;

const EmailDateTitle = styled.h2`
  margin: 0;
  width: 100%;
  flex: 1;
  text-align: center;
  font-size: 1.1em;

  @media (max-width: 768px) {
    width: 100%;
    margin: auto;
    font-size: 0.8em;
  }
`;
// const ChartWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;

//   @media (max-width: 768px) {
//     flex-direction: column;
//     align-items: center;
//   }
// `;
const ChartsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
  margin: auto;
  padding: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0px;
    padding-bottom: 50px;
    margin: 0px;
    width: 100%;
  }
`;

const PieChartContainer = styled.div`
  flex: 1;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  margin: auto;
  padding: 40px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 10px;
  }
`;

const TimeSeriesChartContainer = styled.div`
  flex: 1;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
  padding: 40px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 10px;
  }
`;

const MenuButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  &:hover {
    background-color: #0056b3;
  }
`;

const MenuButtonText = styled.span`
  margin-top: 5px;
  font-size: 14px;
  text-align: center;  // Ensure text is centered
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ModalInput = styled.input`
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: calc(100% - 20px);
`;

const ModalButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin-top: 40px;
  width: 100px;
  margin: 40px 0 0 auto;
  align-items: right;

  &:hover {
    background-color: #0056b3;
  }
`;
// const ChartAlign = styled.div`  
//   display: flex;
//   flex-direction: column;
// `;

const ChartHeading = styled.h3`  

  text-align: center;
 font-size: 0.9em;
  margin: 10px; 
  margin-bottom: 20px; /* Adjust margin to pull the heading closer to the chart */

`;


const Reports = () => {
  const userRole = Cookies.get('userRole'); // Retrieve the userRole from the cookie
  const siteId = Cookies.get('siteId'); // Retrieve the userRole from the cookie
  
  const [blogNames, setBlogNames] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState('');
  const [emails, setEmails] = useState([]);
  const [page, setPage] = useState(1);
  const [viewingEmail, setViewingEmail] = useState(null);
  const [clickedUrls, setClickedUrls] = useState([]);
  //const [blogName, setBlogName] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state    
  const [isDataLoaded, setIsDataLoaded] = useState(false); // New state for checking data load
  const [senderName, setSenderName] = useState('');
  const [metrics, setMetrics] = useState({
    sent: 0,
    opens: 0,
    clicks: 0,
    unsubscribed: 0,
    bounces: 0,
    reactivity: 0
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailForm, setEmailForm] = useState({
    from: '',
    to: '',
    subject: '',
    message: '',
    newsletterUrl: '',
    deliveryRate: '',
    openRate: '',
    optOutRate: '',
  });
  const [showReportsBlock, setShowReportsBlock] = useState(true);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false); // New state variable for PDF generation
  const pdfRef = useRef(null);

  const [timeSeriesData, setTimeSeriesData] = useState({
    dates: [],
    sent: [],
    opens: [],
    clicks: []
  });

  // Function to encrypt text


// Function to encrypt text and make it URL-safe
const encrypt = (text) => {
    if (!text) return "";
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(text), Config.enckey).toString();
    return btoa(ciphertext) // Convert to Base64
        .replace(/\+/g, "-")  // Replace + with -
        .replace(/\//g, "_")  // Replace / with _
        .replace(/=+$/, "");  // Remove trailing =
};

  const fetchTimeSeriesMetrics = useCallback(async () => {
    try {
      const response = await axios.get('/api/time-series-metrics', {
        params: {
          blogId: selectedBlog,
          emailId: viewingEmail.id
        }
      });
      console.log('Time series data response:', response.data);
      setTimeSeriesData(response.data);

    } catch (error) {
      console.error('Error fetching time series data:', error);
    }
  }, [selectedBlog, viewingEmail]);

  useEffect(() => {
    fetchBlogNames();
  }, []);

  useEffect(() => {
    if (viewingEmail) {
      fetchMetrics();
      fetchTimeSeriesMetrics();
    }
  }, [viewingEmail, fetchTimeSeriesMetrics]);

  useEffect(() => {
    if (userRole === "user") {
      fetchEmails(siteId, page);
      setSelectedBlog(siteId);   
    }else {
      if (selectedBlog){
      fetchEmails(selectedBlog, page); 
      }
    }
  }, [userRole, siteId, selectedBlog, page]);

  const fetchBlogNames = async () => {
    try {
      const response = await axios.get('/api/blog-names');
      setBlogNames(response.data); 
      console.log("returned blog names:", response.data);
    } catch (error) {
      console.error('Error fetching blog names:', error);
    }
  };
  const fetchBlogName = async (siteId, emailId) => {
    try {
        const response = await axios.get('/api/blog-name', {
            params: { site_id: siteId, email_id: emailId }
        });
        console.log("returned sender name:", response.data.sender_name);
        //setBlogName(response.data.sender_name);
        setSenderName(response.data.sender_name); // Set the sender name
        return response.data.sender_name;
        
    } catch (error) {
        console.error('Error fetching blog name:', error);
    }
};

  const fetchClickedUrls = async (blogId, emailId) => {
    try {
      const response = await axios.get('/api/clicked-urls', {
        params: { blogId, emailId }
      });
      setClickedUrls(response.data);
    } catch (error) {
      console.error('Error fetching clicked URLs:', error);
    }
  };

  const fetchEmails = async (blogId, page) => {
    try {
      const response = await axios.get('/api/emails', {
        params: { blogId, page, limit: 10 },
      });
      setEmails(response.data);
    } catch (error) {
      console.error('Error fetching emails:', error);
    }
  };

  const fetchBounces = async (blogId, emailId) => {
    try {
      const response = await axios.get('/api/bounces', {
        params: { blogId, emailId }
      });
      setMetrics(prevMetrics => ({ ...prevMetrics, bounces: response.data.bounces }));
      return response.data.bounces;
    } catch (error) {
      console.error('Error fetching bounces:', error);
    }
  };

  const fetchMetrics = async (blogId, emailId) => {
    try {
      const response = await axios.get('/api/metrics', {
        params: { blogId, emailId }
      });

      setMetrics({
        sent: response.data.sent,
        opens: response.data.opens,
        clicks: response.data.clicks,
        unsubscribed: response.data.unsubscribed,
        // bounces: response.data.bounces,
        reactivity: ((response.data.clicks / response.data.opens) * 100).toFixed(1)
      });
      console.log("fetchMetrics:", response.data)
      return response.data;
    } catch (error) {
      console.error('Error fetching metrics:', error);

    }
  };

  const generateChartData = (metrics) => {
    const total = metrics.sent;
    const estimatedOpens = metrics.opens;
    const openedAndClicked = metrics.clicks;
    const optOuts = metrics.unsubscribed;
    const bounces = metrics.bounces;
    const remaining = total - (estimatedOpens + openedAndClicked + optOuts + bounces);

    const data = {
      labels: [
        `Estimated Opens* - ${((estimatedOpens / total) * 100).toFixed(1)}%`,
        `Opened & Clicked - ${((openedAndClicked / total) * 100).toFixed(1)}%`,
        `Opt-outs - ${((optOuts / total) * 100).toFixed(1)}%`,
        `Bounced - ${((bounces / total) * 100).toFixed(1)}%`,
        `Remaining - ${((remaining / total) * 100).toFixed(1)}%`
      ],
      datasets: [{
        data: [estimatedOpens, openedAndClicked, optOuts, bounces, remaining],
        backgroundColor: ['#4BC0C0', '#FF6384', '#FF9F40', '#FFCE56', '#E7E9ED'],
        hoverBackgroundColor: ['#36A2EB', '#FF6384', '#FF9F40', '#FFCE56', '#E7E9ED']
      }]
    };
    return data;
  };

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'left',
      },
    },
  };

  const generateTimeSeriesData = (timeSeriesMetrics) => {
    return {
      labels: timeSeriesMetrics.dates,
      datasets: [
        {
          type: 'bar',
          label: 'Sent',
          data: timeSeriesMetrics.sent,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        },
        {
          type: 'line',
          label: 'Opens',
          data: timeSeriesMetrics.opens,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
          fill: false,
          lineTension: 0.1
        },
        {
          type: 'line',
          label: 'Clicks',
          data: timeSeriesMetrics.clicks,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
          fill: false,
          lineTension: 0.1
        }
      ]
    };
  };

  const timeSeriesOptions = {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month'
        }
      },
      y: {
        beginAtZero: true
      }
    }
  };

  const openModal = () => {
    if (isDataLoaded) {
      setIsModalOpen(true);
    } else {
      console.log('Data not loaded yet');
    }
  };

  const closeModal = () => setIsModalOpen(false);

  const handleSendClick = () => {
    openModal();
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setEmailForm({ ...emailForm, [name]: value });
  };

  const handleSend = async (event) => {
    event.preventDefault();    
    setIsGeneratingPDF(true);
    const { from, to, subject, newsletterUrl, deliveryRate, openRate, optOutRate} = emailForm;
    const toEmails = to.split(',').map(email => email.trim());
    try {
        const bouncesResponse = await axios.get('/api/download-bounces', {
            params: { blogId: selectedBlog, emailId: viewingEmail.id },
            responseType: 'blob',
            validateStatus: function (status) {
              return (status >= 200 && status < 300) || status === 204; // Accept 204 as valid
          }
        });
        //const bouncesBlob = new Blob([bouncesResponse.data], { type: 'text/csv' });
        var bouncesBlob = '';
        if (bouncesResponse.status === 204) {
            console.log('No opt-outs found');          
        } else {
          bouncesBlob = new Blob([bouncesResponse.data], { type: 'text/csv' });
        }


        const optoutsResponse = await axios.get('/api/download-optouts', {
            params: { blogId: selectedBlog, emailId: viewingEmail.id },
            responseType: 'blob',
            validateStatus: function (status) {
              return (status >= 200 && status < 300) || status === 204; // Accept 204 as valid
          }
        });

        var optoutsBlob = '';
        if (optoutsResponse.status === 204) {
            console.log('No opt-outs found');          
        } else {
            optoutsBlob = new Blob([optoutsResponse.data], { type: 'text/csv' });
        }

       // const optoutsBlob = new Blob([optoutsResponse.data], { type: 'text/csv' });

        // Ensure the pdfRef.current is available
        if (pdfRef.current) {
            setLoading(false); 
            const canvas = await html2canvas(pdfRef.current);
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            const pdfBlob = pdf.output('blob');

            const formData = new FormData();
            formData.append('siteid', encrypt(selectedBlog));
            formData.append('emailid', encrypt(viewingEmail.id));
            formData.append('from', from);
            formData.append('to', JSON.stringify(toEmails));
            formData.append('subject', subject);
            formData.append('newsletterUrl', newsletterUrl);
            formData.append('deliveryRate', deliveryRate);
            formData.append('openRate', openRate);
            formData.append('optOutRate', optOutRate);   
            if (bouncesBlob !== ''){
              formData.append('bounces', bouncesBlob, `${viewingEmail.sent_on.split('T')[0]} Mailing - Bounces.csv`);
            }
            if (optoutsBlob !== ''){
              formData.append('optouts', optoutsBlob, `${viewingEmail.sent_on.split('T')[0]} Mailing - Optouts.csv`);
            }
            formData.append('pdf', pdfBlob, `${viewingEmail.sent_on.split('T')[0]} Mailing - Overview Report.pdf`);
           
            setLoading(true); // Set loading to true when sending starts
            await axios.post('/api/send-email', formData);
            closeModal();
            setLoading(false); // Set loading to false after sending is complete
        } else {
            console.error('pdfRef.current is not available');
            setLoading(false); // Set loading to false if an error occurs
        }
    } catch (error) {
        console.error('Error downloading files:', error);
        setLoading(false); // Set loading to false if an error occurs
    } finally {
      setIsGeneratingPDF(false); // Set back to false after PDF generation is complete
  }
};



  const handleDownloadBounces = async () => {
    try {
      const response = await axios.get('/api/download-bounces', {
        params: {
          blogId: selectedBlog,
          emailId: viewingEmail.id
        },
        responseType: 'blob' // Important for handling file download
      });

      // Create a URL for the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'bounces.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading bounces:', error);
    }
  };

  const handleDownloadOptouts = async () => {
    try {
      const response = await axios.get('/api/download-optouts', {
        params: {
          blogId: selectedBlog,
          emailId: viewingEmail.id
        },
        responseType: 'blob' // Important for handling file download
      });

      // Create a URL for the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'optouts.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading opt-outs:', error);
    }
  };

  const handleBlogChange = async (event) => {
    setSelectedBlog(event.target.value);
    setPage(1); // Reset to first page on blog change
  };

  const handleViewClick = async (email) => {
    setViewingEmail(email);
    setShowReportsBlock(false);
    console.log('Selected Email:', email);  // Log the selected email for debugging
    setLoading(true); // Set loading to true

    // Reset the form state
    setEmailForm({
        from: '',
        to: '',
        subject: '',
        message: '',        
        newsletterUrl: '',
        deliveryRate: '',
        openRate: '',
        optOutRate: '',
    });

    // Fetch the necessary data
    const metrics_ret = await fetchMetrics(selectedBlog, email.id);
    const getbounces = await fetchBounces(selectedBlog, email.id);
    await fetchClickedUrls(selectedBlog, email.id);

    try {
        const response = await axios.get('/api/time-series-metrics', { params: { blogId: selectedBlog, emailId: email.id } });
        setTimeSeriesData(response.data);
        console.log(response.data);
    } catch (error) {
        console.error('Error fetching time series data:', error);
    }

    // Fetch the blog name
    const blog_name = await fetchBlogName(selectedBlog, email.id);
    // Fetch the domain of the selected blog        
    const selectedBlogData = blogNames.find(blog => String(blog.blog_id) === String(selectedBlog));
    if (!selectedBlogData) {
        console.error('Selected blog data not found');
        setLoading(false);
        return;
    }
    console.log(metrics_ret.sent, "-",getbounces,"/",metrics_ret.sent);  
    const deliveryRate = (((metrics_ret.sent - getbounces) / metrics_ret.sent) * 100).toFixed(1);
    const openRate = ((metrics_ret.opens / metrics_ret.sent) * 100).toFixed(1);
    const optOutRate = ((metrics_ret.unsubscribed / metrics_ret.sent) * 100).toFixed(1);

    // Extract the subdomain from the domain
    const domainParts = selectedBlogData.domain.split(".");
    const subdomain = domainParts.length > 2 ? domainParts[0] : "default"; // Ensure a valid subdomain

    // Encrypt subdomain and email ID
    const encryptedSubdomain = encrypt(subdomain);
    const encryptedEmailId = encrypt(email.id);
    console.log(encryptedSubdomain);
    console.log(encryptedEmailId);
    // Construct the secure URL

    const newsletterUrl = `${Config.domain}/api/view/${encryptedSubdomain}/${encryptedEmailId}`;

  //  const newsletterUrl = `https://${selectedBlogData.domain}/?na=view&id=${email.id}`;
    const formattedDate = new Date(email.sent_on).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
    }).replace(/ /g, '-').replace(/,/g, '');

    const subject = `${blog_name.trim()} - Report for ${formattedDate} Mailing`;
    console.log(subject);  

    
   
    console.log('Selected Blog Data:', selectedBlogData);
    console.log(deliveryRate, openRate, optOutRate);
    // Preload the email content into the ReactQuill field
    setEmailForm({
        from: '',
        to: '',
        subject: subject,
        newsletterUrl: newsletterUrl,
        deliveryRate: deliveryRate,
        openRate: openRate,
        optOutRate: optOutRate,
        message: ''
    });

    setLoading(false); // Set loading to false after all data is fetched
    setIsDataLoaded(true); // Set data loaded state
};


  const handleBackClick = () => {
    setViewingEmail(null);
    setShowReportsBlock(true);
    setLoading(false); // Ensure loading is false when going back
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      timeZone: 'America/New_York', // Ensures the time is displayed in EST
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  };
  

  const getDate = (dateString) => {  
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString(); // Format the date as you like
    return [formattedDate];
  };
  const getTime = (dateString) => {  
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedTime = `${hours}:${minutes} ${ampm}`;
    //const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Format the time as you like
    return [formattedTime];
  };
  const calculatePercentage = (value, total) => {
    return total > 0 ? ((value / total) * 100).toFixed(1) + '%' : '0%';
  };



  return (
    <ReportsContainer isViewingEmail={!!viewingEmail} isGeneratingPDF={isGeneratingPDF} ref={pdfRef}>
       <Sidebar
        handleBack={handleBackClick}
        handleBounces={handleDownloadBounces}
        handleOptouts={handleDownloadOptouts}
        handleSend={handleSendClick}
      />
      {loading ? (
        <SpinnerContainer>
          <ClipLoader color="#007bff" loading={loading} css={override} size={150} />
        </SpinnerContainer>
      ) : (
        <>
          {/* {viewingEmail && (
            // <TopMenuBar id="top-menu-bar">
            //   <MenuButton onClick={handleBackClick}>
            //     <FaArrowLeft size={20} />
            //     <MenuButtonText>Back</MenuButtonText>
            //   </MenuButton>
            //   <MenuButton onClick={handleDownloadBounces}>
            //     <FaDownload size={20} />
            //     <MenuButtonText>Bounces</MenuButtonText>
            //   </MenuButton>
            //   <MenuButton onClick={handleDownloadOptouts}>
            //     <FaDownload size={20} />
            //     <MenuButtonText>Optouts</MenuButtonText>
            //   </MenuButton>
            //   <MenuButton onClick={handleSendClick}>
            //     <FaPaperPlane size={20} />
            //     <MenuButtonText>Send</MenuButtonText>
            //   </MenuButton>
            // </TopMenuBar>
          )} */}
         
          {showReportsBlock && userRole !== 'user' && (
            <ReportsBlock>
              <label htmlFor="accounts">Accounts:</label>
              <Dropdown id="accounts" value={selectedBlog} onChange={handleBlogChange}>
                <option value="">Select an account</option>
                {blogNames.map((blog) => (
                  <option key={blog.blog_id} value={blog.blog_id}>
                    {blog.domain}
                  </option>
                ))}
              </Dropdown>
            </ReportsBlock>
          )}
          {viewingEmail ? (
            <> <ReportsTitle><h1>{senderName} - Overview Report</h1></ReportsTitle>
              <EmailSubjectContainer>
                <EmailSubjectTitle>"{viewingEmail.subject.trim()}"</EmailSubjectTitle>
              </EmailSubjectContainer>
              <EmailSubjectContainer>
                <EmailDateTitle>Mailed on: {formatDate(viewingEmail.sent_on)}</EmailDateTitle>
              </EmailSubjectContainer>
          
              <ChartsContainer>                
                <PieChartContainer> 
                    <ChartHeading>Current Mailing</ChartHeading>                     
                    <Pie data={generateChartData(metrics)} options={pieOptions} />                  
                </PieChartContainer>
                
                <TimeSeriesChartContainer>
                    <ChartHeading>Historical Summary</ChartHeading>
                    <Line data={generateTimeSeriesData(timeSeriesData)} options={timeSeriesOptions} />
                </TimeSeriesChartContainer>
            </ChartsContainer>

 
              <MetricsContainer>
                <MetricBlock>
                  <MetricTitle>Reach</MetricTitle>
                  <MetricValue>{viewingEmail.sent}</MetricValue>
                  <MetricDescription>Total people that received your email</MetricDescription>
                </MetricBlock>
                <MetricBlock>
                  <MetricTitle>Total Opens*</MetricTitle>
                  <MetricValue>{calculatePercentage(metrics.opens, viewingEmail.sent)}</MetricValue>
                  <MetricDescription>{metrics.opens} total people that opened your email.</MetricDescription>
                  <MetricDisclaimer></MetricDisclaimer>
                </MetricBlock>
                <MetricBlock>
                  <MetricTitle>Clicks</MetricTitle>
                  <MetricValue>{calculatePercentage(metrics.clicks, viewingEmail.sent)}</MetricValue>
                  <MetricDescription>{metrics.clicks} total people that clicked a link in your email</MetricDescription>
                </MetricBlock>
              </MetricsContainer>
              <MetricsContainer>
                <MetricBlock>
                  <MetricTitle>Bounces</MetricTitle>
                  <MetricValue>{metrics.bounces}</MetricValue>
                  <MetricDescription>Emails that bounced back from your email.</MetricDescription>
                </MetricBlock>
                <MetricBlock>
                  <MetricTitle>Reactivity</MetricTitle>
                  <MetricValue>{metrics.reactivity}%</MetricValue>
                  <MetricDescription>{metrics.clicks} clicks out of {metrics.opens} opens</MetricDescription>
                </MetricBlock>
                <MetricBlock>
                  <MetricTitle>Unsubscribed</MetricTitle>
                  <MetricValue>{calculatePercentage(metrics.unsubscribed, viewingEmail.sent)}</MetricValue>
                  <MetricDescription>{metrics.unsubscribed} people opted out from your email</MetricDescription>
                </MetricBlock>
              </MetricsContainer>
              <ClickedUrlsTable>
                <thead>
                  <tr>
                    <ClickedUrlsTh>URL</ClickedUrlsTh>
                    <ClickedUrlsTh>Readers</ClickedUrlsTh>
                    <ClickedUrlsTh>Total Views</ClickedUrlsTh>
                  </tr>
                </thead>
                <tbody>
                  {clickedUrls.map((url, index) => (
                    <tr key={index}>
                      <ClickedUrlsTd>{url.url}</ClickedUrlsTd>
                      <ClickedUrlsTd>{url.unique_clicks}</ClickedUrlsTd>
                      <ClickedUrlsTd>{url.total_views}</ClickedUrlsTd>
                    </tr>
                  ))}
                </tbody>
              </ClickedUrlsTable>
            </>
          ) : (
            <ReportsBlock>
              <Table>
                <thead>
                  <tr>
                    <Th>Id</Th>
                    <Th>Subject</Th>
                    <Th>Total Sent</Th>
                    <Th>Date/Time Sent</Th>
                    <Th>View</Th>
                  </tr>
                </thead>
                <tbody>
                 
                 {emails.map((email) => (                 
                    <tr key={email.id}>
                      <Td>{email.id}</Td>
                      <Td>{email.subject}</Td>
                      <Td>{email.sent}</Td> 
                      <Td>{getDate(email.sent_on)}<br/>{getTime(email.sent_on)}</Td> 
                      <Td><Button onClick={() => handleViewClick(email)}>View</Button></Td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div>
                <Button onClick={() => setPage((prev) => Math.max(prev - 1, 1))} disabled={page === 1}>
                  Previous
                </Button>
                <Button onClick={() => setPage((prev) => prev + 1)}>
                  Next
                </Button>
              </div>
            </ReportsBlock>
          )}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Send Email"
            ariaHideApp={false}
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '50%',
              },
            }}
          >
            <ModalContent>
              <h2>Send Report</h2>

              {loading && (
                <SpinnerContainer>
                  <p>Compiling and sending email, please wait</p>
                  <ClipLoader color="#007bff" loading={loading} css={override} size={150} />
                </SpinnerContainer>
              )}
              {!loading && (
                <ModalForm onSubmit={handleSend}>
                  <ModalInput
                    type="email"
                    name="from"
                    placeholder="From"
                    value={emailForm.from}
                    onChange={handleFormChange}
                    required
                    rows="1"
                  />
                  <ModalInput
                    type="text"
                    name="to"
                    placeholder="To (comma separated)"
                    value={emailForm.to}
                    onChange={handleFormChange}
                    required
                  />
                  <ModalInput
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    value={emailForm.subject}
                    onChange={handleFormChange}
                    required
                  />
         
                  <ModalButton type="submit">Send</ModalButton>
                </ModalForm>
              )}
            </ModalContent>
          </Modal>
        </>
      )}
    </ReportsContainer>
  );
};

export default Reports;
