import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa'; // Import the FaTimes icon

const ScheduledContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 60px; /* Add margin to avoid overlap with mobile header */
    @media (max-width: 768px) {
        margin-left: 0px;
    }
`;

const ScheduledBlock = styled.div`
    background: white;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    flex: 1 1 100%;

    @media (max-width: 768px) {
        flex: 1 1 100%;
    }
`;

const ScheduledTitle = styled.div`
    padding: 0px;
    margin-top: -30px;
    flex: 1 1 100%;
    text-align: center;

    @media (max-width: 768px) {
        flex: 1 1 100%;
    }
`;

/* Additional styling if desired */
const FormRow = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
`;

const Input = styled.input`
    padding: 8px;
    width: 250px;
    max-width: 100%;
`;

const Select = styled.select`
    padding: 8px;
    width: 250px;
    max-width: 100%;
`;

const Button = styled.button`
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }

    &:disabled {
      background-color: #a0a0a0;
      cursor: not-allowed;
    }
`;
const FormWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const Column = styled.div`
    flex: 1;
    padding: 10px;

    @media (max-width: 768px) {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
    }
`;
const Scheduled = () => {
    /* ---- State variables ---- */
    const [blogId, setBlogId] = useState('');
    const [emailId, setEmailId] = useState('');
    const [recipients, setRecipients] = useState('');
    const [scheduleDate, setScheduleDate] = useState('');
    const [scheduleTime, setScheduleTime] = useState('');

    const [blogOptions, setBlogOptions] = useState([]);
    const [emailOptions, setEmailOptions] = useState([]);
    const [schedules, setSchedules] = useState([]);

    const [loading, setLoading] = useState(false);
    const [deletingId, setDeletingId] = useState(null); // To track which schedule is being deleted

    /* ---- Fetch blog names & schedules on mount ---- */
    useEffect(() => {
        fetchBlogNames();
        fetchSchedules();
    }, []);

    // If user selects a blog, fetch the emails for that blog
    useEffect(() => {
        if (blogId) {
            fetchEmails(blogId);
        } else {
            setEmailOptions([]);
        }
    }, [blogId]);

    /* ---- API calls ---- */
    const fetchBlogNames = async () => {
        try {
            setLoading(true);
            const response = await axios.get('/api/blog-names');  
            setBlogOptions(response.data);
        } catch (err) {
            console.error('Error fetching blog names:', err);
            alert('Error fetching blog names');
        } finally {
            setLoading(false);
        }
    };

    const fetchEmails = async (selectedBlogId) => {
        try {
            setLoading(true);
            const response = await axios.get('/api/emails', {
                params: { blogId: selectedBlogId, page: 1, limit: 50 },
            });
            setEmailOptions(response.data);
        } catch (err) {
            console.error('Error fetching emails:', err);
            alert('Error fetching emails');
        } finally {
            setLoading(false);
        }
    };

    const fetchSchedules = async () => {
        try {
            setLoading(true);
            const response = await axios.get('/api/schedules');  
            setSchedules(response.data);
        } catch (err) {
            console.error('Error fetching schedules:', err);
            alert('Error fetching schedules');
        } finally {
            setLoading(false);
        }
    };

    /* ---- Handle form submission ---- */
    const handleScheduleSubmit = async (e) => {
        e.preventDefault();
        if (!blogId || !emailId || !recipients || !scheduleDate || !scheduleTime) {
            alert('Please fill in all fields.');
            return;
        }
        const scheduleAt = `${scheduleDate} ${scheduleTime}`;

        try {
            setLoading(true);
            await axios.post('/api/schedules', {
                blogId,
                emailId,
                recipients,
                scheduleDateTime: scheduleAt,
            });
            alert('Schedule created successfully!');
            // Reset form
            setBlogId('');
            setEmailId('');
            setRecipients('');
            setScheduleDate('');
            setScheduleTime('');
            setEmailOptions([]);
            // Fetch updated list
            fetchSchedules();
        } catch (err) {
            console.error('Error creating schedule:', err);
            alert('Error creating schedule');
        } finally {
            setLoading(false);
        }
    };

    /* ---- Handle delete schedule ---- */
    const handleDelete = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this schedule?');
        if (!confirmDelete) return;

        try {
            setDeletingId(id);
            await axios.delete(`/api/schedules/${id}`);
            // Remove the deleted schedule from the state
            setSchedules((prevSchedules) => prevSchedules.filter((sched) => sched.id !== id));
            //alert('Schedule deleted successfully!');
        } catch (err) {
            console.error('Error deleting schedule:', err);
            alert('Error deleting schedule');
        } finally {
            setDeletingId(null);
        }
    };

    return (
        <ScheduledContainer>
            <ScheduledTitle>
                <h1>Scheduled Reports</h1>
            </ScheduledTitle>

            <ScheduledBlock>
                <h2>Create New Schedule</h2>
                <form onSubmit={handleScheduleSubmit}>
                    <FormWrapper>
                        {/* Left Column */}
                        <Column>
                            <FormRow>
                                <Label>Account (Blog):</Label>
                                <Select
                                    value={blogId}
                                    onChange={(e) => setBlogId(e.target.value)}
                                >
                                    <option value="">Select account</option>
                                    {blogOptions.map((blog) => (
                                        <option key={blog.blog_id} value={blog.blog_id}>
                                            {blog.domain} ({blog.blog_id})
                                        </option>
                                    ))}
                                </Select>
                            </FormRow>

                            <FormRow>
                                <Label>Email:</Label>
                                <Select
                                    value={emailId}
                                    onChange={(e) => setEmailId(e.target.value)}
                                    disabled={!blogId} // disable if no blog selected
                                >
                                    <option value="">Select Email</option>
                                    {emailOptions.map((email) => (
                                        <option key={email.id} value={email.id}>
                                            {email.subject} (ID:{email.id})
                                        </option>
                                    ))}
                                </Select>
                            </FormRow>
                            <FormRow>
                                <Label>Recipients (comma-separated):</Label>
                                <Input
                                    type="text"
                                    value={recipients}
                                    onChange={(e) => setRecipients(e.target.value)}
                                    placeholder="someone@domain.com, other@domain.com"
                                />
                            </FormRow>
                        </Column>

                        {/* Right Column */}
                        <Column>                       

                            <FormRow>
                                <Label>Schedule Date:</Label>
                                <Input
                                    type="date"
                                    value={scheduleDate}
                                    onChange={(e) => setScheduleDate(e.target.value)}
                                />
                            </FormRow>

                            <FormRow>
                                <Label>Schedule Time:</Label>
                                <Input
                                    type="time"
                                    value={scheduleTime}
                                    onChange={(e) => setScheduleTime(e.target.value)}
                                />
                            </FormRow>

                            <Button type="submit" disabled={loading}>
                                {loading ? 'Saving...' : 'Schedule'}
                            </Button>
                        </Column>
                    </FormWrapper>
                </form>
            </ScheduledBlock>

            {/* Existing Schedules Block */}
            <ScheduledBlock>
                <h2>Existing Schedules</h2>
                {loading && <p>Loading schedules...</p>}
                {!loading && schedules.length === 0 && <p>No schedules found.</p>}
                {!loading && schedules.length > 0 && (
                    <table border="1" cellPadding="8" style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>BlogID</th>
                                <th>EmailID</th>
                                <th>Recipients</th>
                                <th>Schedule At</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {schedules.map((sched) => (
                                <tr key={sched.id}>
                                    <td>{sched.id}</td>
                                    <td>{sched.blog_id}</td>
                                    <td>{sched.email_id}</td>
                                    <td>{sched.recipients}</td>
                                    <td>{sched.schedule_at}</td>
                                    <td>{sched.status}</td>
                                    <td>
                                        {deletingId === sched.id ? (
                                            <span>Deleting...</span>
                                        ) : (
                                            <FaTimes
                                                style={{ cursor: 'pointer', color: 'red' }}
                                                onClick={() => handleDelete(sched.id)}
                                                title="Delete Schedule"
                                            />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </ScheduledBlock>
        </ScheduledContainer>
    );
};

export default Scheduled;
