// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation  } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import Dashboard from './components/Dashboard';
import Reports from './components/Reports';
import Builder from './components/Builder';
import Templates from './components/Templates';
import NotificationSettings from './components/Notifications';
import Articles from './components/Articles';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPass';
import UserAccounts from './components/UserAccounts';
import Schedule from './components/Scheduled';
import ProtectedRoute from './ProtectedRoute';
import Sidebar from './components/Sidebar';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import SingleReport from './components/SingleReport'; // Adjust the path as needed



const App = () => {
  const location = useLocation();
  
  // Decide if you want to hide the sidebar for certain paths
  const showSidebar = location.pathname !== '/' 
    && location.pathname !== '/forgot-password' 
    && !location.pathname.startsWith('/reset-password')
    // If you want to hide sidebar on single report, add a condition like:
    && !location.pathname.startsWith('/report/');

  return (
    <div className={`app-container ${showSidebar ? 'with-sidebar' : ''}`}>
      {showSidebar && <Sidebar />}
      <div className="main-content">
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />

          {/* Your protected routes */}
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/reports" element={<ProtectedRoute><Reports /></ProtectedRoute>} />
          <Route path="/builder" element={<ProtectedRoute><Builder /></ProtectedRoute>} />
          <Route path="/articles" element={<ProtectedRoute><Articles /></ProtectedRoute>} />
          <Route path="/templates" element={<ProtectedRoute><Templates /></ProtectedRoute>} />
          <Route path="/notifications" element={<ProtectedRoute><NotificationSettings /></ProtectedRoute>} />
          <Route path="/schedule" element={<ProtectedRoute><Schedule /></ProtectedRoute>} />
          <Route path="/user-accounts" element={<ProtectedRoute><UserAccounts /></ProtectedRoute>} />

          {/* New public route for single report */}
          <Route path="/report/:blogId/:emailId" element={<SingleReport />} />
        </Routes>
      </div>
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
