import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { FormContainer, FormBox, Form, Label, Input, Button } from './FormStyles'; // Import the shared styles
import './LoginPage.css';


const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [keepLoggedIn, setKeepLoggedIn] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        console.log('Login form submitted');
    
        try {
          
            const response = await axios.post('/api/login', { email, password });

            console.log('Response:', response.data);
            setMessage(`Welcome, ${response.data.user.email}!`);
    
            const expires = keepLoggedIn ? 30 : 1; // 30 days if keepLoggedIn is true, otherwise 1 day
            Cookies.set('authToken', response.data.token, { expires });
            Cookies.set('userRole', response.data.user.role, { expires }); // Save the user role in a cookie
            Cookies.set('siteId', response.data.user.site_id, { expires }); // Save the user role in a cookie
            if (response.data.user.role === "admin"){
                navigate('/dashboard'); // Redirect to the dashboard
            }else{
                navigate('/reports'); // Redirect to the dashboard
            }
            
        } catch (error) {
            console.error('Error:', error);
            // Check if error response exists
            if (error.response) {
                // Log the entire response for debugging
                console.log('Response error data:', error.response.data);
                console.log('Response error status:', error.response.status);
                console.log('Response error headers:', error.response.headers);
        
                // Display the error message to the user
                setMessage(`Error: ${error.response.data.msg || 'An error occurred on the server.'}`);
            } else if (error.request) {
                // The request was made, but no response was received
                console.log('Request error:', error.request);
                setMessage('No response received from the server.');
            } else {
                // Something happened while setting up the request
                console.log('Error details:', error.message);
                setMessage(`Request setup error: ${error.message}`);
            }
        }
        
    };
    

    return (
        <div className="page-container">
            <div className="left-half">
                <div className="login-view-container">
                    <div className="login-view-box">
                        <div className="login-view">
                            <h1>Welcome</h1>
                            <h2>to VehicleSend</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right-half">
                <FormContainer>
                    <FormBox>
                        <img src="" alt="Logo" className="logo" />
                        <Form onSubmit={handleLogin}>
                            <Label>Email address:</Label>
                            <Input
                                type="email"
                                placeholder="Email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <Label>Password:</Label>
                            <Input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <div className="input-group checkbox-group">
                                <input
                                    type="checkbox"
                                    id="keep-logged-in"
                                    checked={keepLoggedIn}
                                    onChange={(e) => setKeepLoggedIn(e.target.checked)}
                                />
                                <label htmlFor="keep-logged-in">Keep logged in</label>
                            </div>
                            <Button type="submit">Login</Button>
                            <a href="/forgot-password" className="forgot-password">Forgot Password?</a>
                        </Form>
                        {message && <p>{message}</p>}
                    </FormBox>
                </FormContainer>
            </div>
        </div>
    );
};

export default LoginPage;
