// Templates.js

import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';

// -------------- Styled Components --------------
const Toolbar = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 10px;
  background-color: #6e59a4;
`;

const ToolbarButton = styled.button`
  background-color: #007bff;
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 90%; /* 90% page width */
  margin: auto;
`;

const Section = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;  
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: ${({ show }) => (show ? 'block' : 'none')};
  z-index: 999;
`;

const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  max-width: 800px;
  max-height: 80%;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 5px;
  padding: 20px;
`;

const ModalTitle = styled.h2`
  margin-top: 0;
`;

const TemplateListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const TemplateListItem = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;

  &:hover {
    background-color: #f2f2f2;
  }
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;

const ModalButton = styled.button`
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

// ------------ Utility Function for S3 URL ------------
const getS3ImageUrl = (fileName, region = 'us-east-1') => {
  return `https://image-upload-articlebuilder.s3.${region}.amazonaws.com/${fileName}`;
};

// -------------- TinyMCE Editor --------------
const TinyMCEEditor = ({ value, onEditorChange }) => {
  const editorRef = useRef(null);

  // ---------- Same Image Upload Logic as in Articles.js -----------
  const handleImageUpload = async (blobInfo) => {
    const file = blobInfo.blob();
    const fileName = `${Date.now()}_${file.name}`;
    const fileType = file.type;

    console.log('Starting image upload:', { fileName, fileType });

    try {
      // Step 1: Get Pre-signed URL from Backend
      const presignedResponse = await axios.post('/api/generate-presigned-url', {
        fileName,
        fileType,
      });

      if (presignedResponse.status !== 200) {
        console.error('Failed to fetch pre-signed URL:', presignedResponse.statusText);
        throw new Error('Failed to fetch pre-signed URL');
      }

      const { url } = presignedResponse.data;
      if (!url) {
        console.error('Pre-signed URL is undefined');
        throw new Error('Pre-signed URL is undefined');
      }

      console.log('Received pre-signed URL:', url);

      // Step 2: Upload directly to S3
      const uploadResponse = await axios.put(url, file, {
        headers: {
          'Content-Type': fileType,
        },
      });

      if (uploadResponse.status === 200 || uploadResponse.status === 204) {
        // Step 3: Construct the public S3 URL
        const region = 'us-east-1'; 
        const imageUrl = getS3ImageUrl(fileName, region);
        console.log('Image uploaded successfully:', imageUrl);
        return imageUrl;
      } else {
        console.error('Image upload failed with status:', uploadResponse.status);
        throw new Error('Upload failed');
      }
    } catch (error) {
      console.error('Error during image upload:', error);
      throw new Error('Image upload failed. Please try again.');
    }
  };

  return (
    <Editor
      apiKey="m55v8vhqgiqm3qjbwg7i5kz1aihykam7xntip3isbka36yx6"
      onInit={(evt, editor) => (editorRef.current = editor)}
      value={value}
      init={{
        height: 500,
        menubar: true,
        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount code',
        toolbar:
          'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image table | align lineheight | numlist bullist indent outdent | removeformat | code',

        // ----- Our Image Upload Handler -----
        images_upload_handler: handleImageUpload,
        file_picker_types: 'image',
        image_title: true,
        automatic_uploads: true,

        // ----- Optional File Picker -----
        file_picker_callback: (callback, value, meta) => {
          if (meta.filetype === 'image') {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.onchange = function () {
              const file = this.files[0];
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                const id = 'blobid' + new Date().getTime();
                const blobCache = editorRef.current.editorUpload.blobCache;
                const base64 = reader.result.split(',')[1];
                const blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);
                callback(blobInfo.blobUri(), { title: file.name });
              };
            };
            input.click();
          }
        },

        // Additional TinyMCE options...
        menu: {
          favs: { title: 'tools', items: 'code' },
        },
        menubar: 'file edit view insert format favs table help',

        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      }}
      onEditorChange={onEditorChange}
    />
  );
};

// -------------- Main Component --------------
const TemplatesEditor = () => {
  // Fields
  const [templateTitle, setTemplateTitle] = useState('');
  const [templateContent, setTemplateContent] = useState('');
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  // Modal
  const [showModal, setShowModal] = useState(false);
  const [templatesList, setTemplatesList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  // ---------------- Toolbar Handlers ----------------
  const handleViewTemplates = async () => {
    try {
      const res = await axios.get('/api/templates');
      setTemplatesList(res.data);
      setShowModal(true);
      setSelectedTemplate(null);
    } catch (err) {
      console.error('Error fetching templates:', err);
      alert('Unable to fetch templates.');
    }
  };

  const handleDelete = async () => {
    if (!selectedTemplateId) {
      alert('No template is currently loaded to delete.');
      return;
    }
    if (!window.confirm('Are you sure you want to delete this template?')) {
      return;
    }
    try {
      const res = await axios.delete(`/api/templates/${selectedTemplateId}`);
      if (res.status === 200) {
        alert(res.data.message);
        clearFields();
      }
    } catch (err) {
      console.error('Error deleting template:', err);
      alert('Error deleting template.');
    }
  };

  // ---------------- Modal Handlers ----------------
  const handleSelectTemplate = (template) => {
    setSelectedTemplate(template);
  };

  const handleLoadSelectedTemplate = () => {
    if (!selectedTemplate) return;
    setTemplateTitle(selectedTemplate.title || '');
    setTemplateContent(selectedTemplate.templateContent || '');
    setSelectedTemplateId(selectedTemplate.id);
    setShowModal(false);
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };

  // ---------------- Save / Update ----------------
  const clearFields = () => {
    setTemplateTitle('');
    setTemplateContent('');
    setSelectedTemplateId(null);
  };

  const handleSaveTemplate = async () => {
    try {
      const payload = {
        title: templateTitle,
        templateContent,
      };

      let res;
      if (selectedTemplateId) {
        // If we have an ID, update
        res = await axios.put(`/api/templates/${selectedTemplateId}`, payload);
      } else {
        // Otherwise, create new
        res = await axios.post('/api/templates', payload);
      }

      if (res.status === 200) {
        alert(res.data.message);
        clearFields();
      }
    } catch (err) {
      console.error('Error saving template:', err);
      alert('Error saving template. Check console for details.');
    }
  };

  // ---------------- Editor Handler ----------------
  const handleEditorChange = useCallback((content) => {
    setTemplateContent(content);
  }, []);

  // ---------------- Render ----------------
  return (
    <>
      {/* Toolbar */}
      <Toolbar>
        <ToolbarButton onClick={handleViewTemplates}>View Templates</ToolbarButton>
        <ToolbarButton onClick={handleDelete}>Delete</ToolbarButton>
      </Toolbar>

      {/* Main Body */}
      <PageContainer>
        <Section>
          <SectionTitle>Template Title</SectionTitle>
          <Input
            type="text"
            placeholder="Enter template title"
            value={templateTitle}
            onChange={(e) => setTemplateTitle(e.target.value)}
          />
        </Section>

        <Section>
          <SectionTitle>Template Content</SectionTitle>
          <TinyMCEEditor
            value={templateContent}
            onEditorChange={handleEditorChange}
          />
        </Section>

        <SubmitButton onClick={handleSaveTemplate}>
          {selectedTemplateId ? 'Update Template' : 'Save Template'}
        </SubmitButton>
      </PageContainer>

      {/* Modal */}
      <ModalOverlay show={showModal}>
        <ModalContent>
          <ModalTitle>Available Templates</ModalTitle>
          <TemplateListContainer>
            {templatesList.map((template) => (
              <TemplateListItem
                key={template.id}
                onClick={() => handleSelectTemplate(template)}
                style={{
                  backgroundColor:
                    selectedTemplate && selectedTemplate.id === template.id
                      ? '#e6f7ff'
                      : 'transparent',
                }}
              >
                <strong>{template.title || 'Untitled'}</strong>
              </TemplateListItem>
            ))}
          </TemplateListContainer>
          <ModalButtonContainer>
            <ModalButton onClick={handleLoadSelectedTemplate}>Load</ModalButton>
            <ModalButton onClick={handleCancelModal}>Cancel</ModalButton>
          </ModalButtonContainer>
        </ModalContent>
      </ModalOverlay>
    </>
  );
};

export default TemplatesEditor;
